import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { GOOG_API_KEY } from '../../config';
import { URL, IMG_URL } from '../../config';
import AddPhotoButton from '../Blogs/AddPhotoButton';
import Loading from '../../images/loading.gif';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import PostPopup from './PostPopup';
import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import heic2any from 'heic2any';


const ItemPhoto = ({ item, index, folderid, blogView, placeId, setPlace, handleShow, postView, folderName, folderId }) => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isVideo, setIsVideo] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [filePath, setFilePath] = useState('');
    const videoRef = useRef(null);


    const getCurrentPhoto = async () => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            const file = item.user_photos[photoIndex];
            const fileType = file.filename.split('.').pop().toLowerCase();
    
            if (fileType === 'mov' || fileType === 'mp4') {
                setFilePath(`${IMG_URL}/${file.path}#t=0.001`); // Return video file URL with time offset
            } else {
                setFilePath(`${IMG_URL}/${file.path}`); // Return non-HEIC image URL
            }
        } else {
            setFilePath(`url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY})`);
        }
    }

    const returnVideo = (photoIndex) => {
        if (!!isVideo) {
            return (
            <video className='feed-img' controls autoPlay muted playsInline>
                <source src={`${URL}/${item.user_photos[photoIndex].filename}`} type="video/mp4" />
                {/* <source src={getCurrentPhoto(photoIndex)} type="video/ogg" />
                <source src={getCurrentPhoto(photoIndex)} type="video/webm" /> */}
                Your browser does not support the video tag.
            </video>
            )
        }
    }

    useEffect(() => {
        if (!!item.user_photos && item.user_photos.length > 0) {
            const fileType = item.user_photos[photoIndex].filename.split('.')[item.user_photos[photoIndex].filename.split('.').length - 1];
            if (fileType == 'mov' || fileType == 'mp4') {
                setVideoUrl(`${IMG_URL}/${item.user_photos[photoIndex].path}`);
                setIsVideo(true);
            } else {
                setIsVideo(false);
            }
        }
    }, [photoIndex, item])

    const handlePhotoClick = () => {
        if (!isMobile && !postView) {
            if (videoRef.current) {
                videoRef.current.pause(); // Pause the video when the modal is opened
                setShowModal(true);
            } else {
                setShowModal(true);
            }
        }
    };

    const handleModalClose = () => {
        if (videoRef.current) {
            videoRef.current.pause(); // Ensure the video is paused when the modal is closed
            videoRef.current.currentTime = 0;
        }
        setShowModal(false);
    };

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 768); // Assuming devices with width <= 768px are mobile
        };
    
        handleResize(); // Set the initial value
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      useEffect(() => {
        getCurrentPhoto();
      }, [item, photoIndex]);

    return (
        <>
            {!!item.user_photos && item.user_photos.length > 0 ? (
                <Row className="feed-img-container mr-0 ml-0 pl-0 pr-0 mb-1 mt-1 position-relative" style={{backgroundImage: "none", cursor: !isMobile && "pointer" }}>
                    {!isVideo && <Image 
                                    src={filePath} 
                                    loading='lazy' 
                                    className="feed-img pl-0 pr-0 mr-0 ml-0" 
                                    onClick={handlePhotoClick}
                                    alt={`${item.notes ? item.notes : 'User travel photo.'}`}
                                />}
                    {!!isVideo && (
                            <video 
                                key={videoUrl} 
                                ref={videoRef}
                                className='feed-img' 
                                preload='metadata' 
                                controls
                                playsInline
                                onClick={handlePhotoClick}
                            >
                                <source src={filePath} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                    )}
                    {photoIndex < item.user_photos.length -1 && (
                            <ChevronRight
                                onClick={() => {
                                    setPhotoIndex(index => index + 1);
                                }}
                                size={40}
                                color='white'
                                className='right-arrow mr-2'
                                style={{ zIndex: "3" }}
                            />
                    )}
                    {photoIndex > 0 && (
                            <ChevronLeft
                                onClick={() => {
                                    setPhotoIndex(index => index - 1)
                                }}
                                size={40}
                                color='white'
                                className='left-arrow ml-2'
                                style={{ zIndex: "3"}}
                            />
                    )}
                    {loading && (
                        <div className='loading'>
                            <Image src={Loading} className='mw-100' alt='loading...' />
                        </div>
                    )}
                </Row> 
                )  : (
                <div className='pr-3 pl-3'>
                <Row onClick={(e) => handleShow()} role="button" className="item-img-container position-relative mr-0 ml-0 pl-0 pr-0 mb-1" style={{backgroundImage: filePath }}>
                    {/* <Image src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${item.photos[0].photo_reference}&key=${GOOG_API_KEY}`} className="item-img pl-0 pr-0 mr-0 ml-0" /> */}
                    {loading && (
                        <div className='loading'>
                            <Image src={Loading} className='mw-100' alt='loading...' />
                        </div>
                    )}
                </Row>
                </div>
            )}
            {!!blogView && (
                <Row className='mt-1 mr-0 ml-0'>
                    <Col className='text-center'>
                        <AddPhotoButton 
                            index={index} 
                            folderId={folderid} 
                            item={item} 
                            photoIndex={photoIndex} 
                            setPhotoIndex={setPhotoIndex} 
                            setLoading={setLoading}
                        />
                    </Col>
                </Row>
            )}
            <PostPopup item={item} showModal={showModal} setShowModal={handleModalClose} folderName={folderName} folderId={folderId} />
        </>
    )
}

export default ItemPhoto;