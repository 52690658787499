import React from 'react';
import { Star } from 'lucide-react';

const Details = ({place, handleShow, postView}) => {

    const formatType = (type) => {
        return type.split('_') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const formatLocStr = (item) => {
        let strArr = [''];
        // console.log('item plus code: ', item.plus_code);
        if (!!item.plus_code && item.plus_code !== undefined) {
            if (!!item.plus_code.compound_code && item.plus_code.compound_code.includes(' ')) {
                strArr = item.plus_code ? item.plus_code.compound_code.split(' ') : [''];
            }
        } 
        let newLocStr = '';
        // console.log('Loc Str Arr: ', strArr);
        for (let i = 1; i < strArr.length; i++) {
            newLocStr += strArr[i]
            if (i < strArr.length -1) {
                newLocStr += ' ';
            }

        }
        // console.log('new location string: ', newLocStr);
        if (!!newLocStr && newLocStr.includes(', ')) {
            let newLocArr = newLocStr.split(', ');
            // console.log('new location arr: ', newLocArr);
            newLocStr = newLocArr[newLocArr.length - 2] + ', ' + newLocArr[newLocArr.length -1];
        }
        // console.log('new location string: ', newLocStr);
        // console.log('item formatted address: ', item.formatted_address);
        if (!newLocStr) {
            if (!!item.formatted_address && item.formatted_address.includes(', ')) {
                let strArr = item.formatted_address.split(', ');
                if (!!strArr[strArr.length -3]) {
                    newLocStr += strArr[strArr.length -3];
                    newLocStr += ', ' 
                }
                let cityArr;
                if (strArr[strArr.length -2].includes(' ') && !!strArr[strArr.length - 2]) {
                    cityArr = strArr[strArr.length -2].split(' ');
                } else {
                    cityArr = [strArr[strArr.length -2]];
                }
                // console.log('city array: ', cityArr);
                for (let i = 0; i < cityArr.length; i++) {
                    if (!Number(cityArr[i])) {
                        newLocStr += cityArr[i];
                        if (i < cityArr.length -1 && !Number(cityArr[i+1])) {
                            newLocStr += ' ';
                        }
                    }
                }
                newLocStr += ', ';
                newLocStr += strArr[strArr.length -1];
            } else {
                newLocStr = item.formatted_address;
            }
        }
        // console.log('final new location string: ', newLocStr);
        return newLocStr;
    };
    
    return (
        <>
        <p className='text-primary font-weight-bold mb-0 pb-0 cursor-pointer mr-2' onClick={() => handleShow()}>{place.name}</p>
        {!postView && !!place && (
            <p className="geo-text mb-0">{formatLocStr(place)}</p>
        )}
        <div style={{ marginTop: "-0.3rem"}}>
            <div className='d-inline-block'>
                {!!place && !!place.types &&  (
                        <p className='geo-text mr-1 d-inline-block text-wrap mb-0' style={{ marginBottom: "0", paddingBottom: "0", marginTop: "0", paddinTop: "0" }}>
                        {formatType(place.types[0])}
                        </p>
                )}
                </div>
                {!!place.rating && !isNaN(place.rating) && (
                    <div className='d-inline-block' style={{ marginTop: "-0.3rem"}}>
                        <p className='geo-text mb-0 d-inline'><span className='mr-1' style={{ fontSize: "0.2rem", verticalAlign: "middle" }}>⬤</span> {place.rating} </p>
                        <Star className='d-inline' size={12} fill='#424242' color='#424242' />
                    </div>
                )}
        </div>
        </>
    );
};

export default Details;