import React, { useState } from 'react';
import { Button, Image, Container, Row, Col, Modal } from 'react-bootstrap';
// import { Modal } from '../Modal';
import Rating from './Rating';
import { GOOG_API_KEY } from '../../config';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { URL } from '../../config';
import BookmarkButton from './BookmarkButton';
import { Trash, ChevronLeft, ChevronRight, X, Star } from 'lucide-react';

const InfoModal = ({ folders, addAble, wishList, deleteItem, folderid, userId, index, placeId, place, setPlace, showModal, setShowModal, folder, searchView, postView }) => {
    const [showDetails, setShowDetails] = useState(false);
    const folderSize = "md";
    const [photoIndex, changePhotoIndex] = useState(0);
    // const [item, setItem] = useState({});
    // const [showModal, setShowModal] = useState(false);

    

    const handleClose = () => {
        setShowModal(false);
        changePhotoIndex(0);
    }
    const handleShow = async (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Places',
            action: 'User Viewed Place Details'
        })
        return axios.get(`${URL}/places/details/${placeId}`)
        .then(({data}) => {
            // console.log('response: ', data.result)
            setPlace(data.result);
            return data;
        })
        .then((data) => {
            if (!!data.result) {
                setShowModal(true);
            } else {
                console.log('Error: Place not found.');
            }
            
        })
        .catch(err => {
            console.log(err)
        })
    }




    const toggleDetails = () => setShowDetails(!showDetails);

    const capitalFirstLetter = (item) => {
        if (!!item.types) {
            let word = item.types[0];
            const splitWord = word.split('_');
            // console.log('split word: ', splitWord);
            let wordString = '';
            for (let i = 0; i < splitWord.length; i++) {
                let firstLetter = splitWord[i].charAt(0);
                let firstLetterCap = firstLetter.toUpperCase();
                let remainingLetters = splitWord[i].slice(1);
                let capitalizedWord = firstLetterCap + remainingLetters;
                wordString += capitalizedWord;
                if (i < splitWord.length -1) {
                    wordString += ' '
                }
                // console.log('updated word string: ', wordString);
            }
            return wordString;
        }
    }

    const openGoogleMaps = (latitude, longitude, placeId) => {
        ReactGA.event({
            category: "Places",
            action: "User Viewed Place in Maps"
        })
        if( (navigator.userAgent.includes("iPhone")) 
        || (navigator.userAgent.includes("iPod"))
        || (navigator.userAgent.includes("iPad"))) {
         window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&query_place_id=${placeId}`);
        } else {
         window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&query_place_id=${placeId}`);
        }
    }

    const openWebsiteTab = (url, e) => {
        // e.preventDefault();
        ReactGA.event({
            category: "Places",
            action: "User Viewed Link to Place's Website"
        })
        window.open(url);
    }

    const handleDelete = e => {
        e.preventDefault();
        deleteItem(index, folderid, userId)
        handleClose();
    }

    const getCurrentPhoto = (photoIndex) => {
        if (!!place && !!place.photos && !!place.photos[photoIndex]) {
            return `url(https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photo_reference=${place.photos[photoIndex].photo_reference}&key=${GOOG_API_KEY})`;
        }
    }

    const formatType = (type) => {
        return type.split('_') 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const formatLocStr = (item) => {
        let strArr = [''];
        // console.log('item plus code: ', item.plus_code);
        if (!!item.plus_code && item.plus_code !== undefined) {
            if (!!item.plus_code.compound_code && item.plus_code.compound_code.includes(' ')) {
                strArr = item.plus_code ? item.plus_code.compound_code.split(' ') : [''];
            }
        } 
        let newLocStr = '';
        // console.log('Loc Str Arr: ', strArr);
        for (let i = 1; i < strArr.length; i++) {
            newLocStr += strArr[i]
            if (i < strArr.length -1) {
                newLocStr += ' ';
            }

        }
        // console.log('new location string: ', newLocStr);
        if (!!newLocStr && newLocStr.includes(', ')) {
            let newLocArr = newLocStr.split(', ');
            // console.log('new location arr: ', newLocArr);
            newLocStr = newLocArr[newLocArr.length - 2] + ', ' + newLocArr[newLocArr.length -1];
        }
        // console.log('new location string: ', newLocStr);
        // console.log('item formatted address: ', item.formatted_address);
        if (!newLocStr) {
            if (!!item.formatted_address && item.formatted_address.includes(', ')) {
                let strArr = item.formatted_address.split(', ');
                if (!!strArr[strArr.length -3]) {
                    newLocStr += strArr[strArr.length -3];
                    newLocStr += ', ' 
                }
                let cityArr;
                if (strArr[strArr.length -2].includes(' ') && !!strArr[strArr.length - 2]) {
                    cityArr = strArr[strArr.length -2].split(' ');
                } else {
                    cityArr = [strArr[strArr.length -2]];
                }
                // console.log('city array: ', cityArr);
                for (let i = 0; i < cityArr.length; i++) {
                    if (!Number(cityArr[i])) {
                        newLocStr += cityArr[i];
                        if (i < cityArr.length -1 && !Number(cityArr[i+1])) {
                            newLocStr += ' ';
                        }
                    }
                }
                newLocStr += ', ';
                newLocStr += strArr[strArr.length -1];
            } else {
                newLocStr = item.formatted_address;
            }
        }
        // console.log('final new location string: ', newLocStr);
        return newLocStr;
    };

    
    return (
        <>
            {/* <Button size="md" 
                className="btn-white mb-0 p-0 ml-2 mt-1" 
                style={{ right: "0" }} 
                onClick={handleShow}>
                    <Image className="m-0 filter-secondary" style={{ height: "1.8em" }} src={InfoIcon} />
            </Button> */}
            <Modal show={showModal} onHide={handleClose} className='p-0' >
                <Container className="pr-0 pl-0 pb-0 m-0 img-modal-container" style={{ backgroundImage: getCurrentPhoto(photoIndex) }}>
                        <button onClick={handleClose} className="modal-close p-1 mt-2 mr-2 text-center" type="button" data-dismiss="modal" aria-label="Close">
                            {/* <div className='w-100 h-100 text-center'>
                                <Image className='close-x' src={Close} />
                            </div> */}
                            <X color='black' className='full-center' size={30} />
                        </button>
                        <Button onClick={handleClose}>Close</Button>
                        {!!place && !!place.photos && photoIndex < place.photos.length -1  && (
                            <ChevronRight
                                onClick={() => {
                                    changePhotoIndex(index => index + 1);
                                }}
                                size={40}
                                color='white'
                                className='right-arrow mr-2'
                                style={{ zIndex: "3" }}
                            />
                        )}
                        {!!place.photos && photoIndex > 0 && (
                            <ChevronLeft
                                onClick={() => {
                                    changePhotoIndex(index => index - 1)
                                }}
                                size={40}
                                color='white'
                                className='left-arrow ml-2'
                                style={{ zIndex: "3"}}
                            />
                        )}
                </Container>
                <Container className="pb-2 mb-2">
                    <Row className="mt-2 mb-1">
                    <Col className="left mb-0 pb-0">
                        <h5 className="mb-0">{place.name}</h5>
                    </Col>
                    </Row>
                    <Row className="pb-1 mb-0" style={{ marginTop: "-0.3rem" }}>
                        <Col className="col-8" style={{ width: "80%" }}>
                            {!postView && !!place && (
                                <h4 className="geo-text mb-0">{formatLocStr(place)}</h4>
                            )}
                            <div style={{ marginTop: "-0.2rem"}}>
                                <div className='d-inline-block'>
                                    {!!place && !!place.types &&  (
                                            <p className='geo-text mr-1 d-inline-block text-wrap mb-0' style={{ marginBottom: "0", paddingBottom: "0", marginTop: "0", paddinTop: "0" }}>
                                            {formatType(place.types[0])}
                                            </p>
                                    )}
                                    </div>
                                    {place.rating && (
                                        <div className='d-inline-block' style={{ marginTop: "-0.3rem"}}>
                                            <p className='geo-text mb-0 d-inline'><span className='mr-1' style={{ fontSize: "0.2rem", verticalAlign: "middle" }}>⬤</span> {place.rating} </p>
                                            <Star className='d-inline' size={12} fill='#424242' color='#424242' />
                                        </div>
                                    )}
                            </div>
                        </Col>
                        <Col className="right-align pl-0">
                            <BookmarkButton bookMarkSize={"2em"} item={place} folders={folders} wishList={wishList} addAble={addAble} folder={folder} searchView={searchView} />
                            {!addAble && (
                                <Button size="md" className="btn-transparent mb-0 mr-0 p-0 ml-1 mt-1" onClick={handleDelete}>
                                    <Trash size={"2em"} color='#424242' />
                                </Button>
                            )}
                        </Col>
                    </Row>
                    {!!place.editorial_summary && (
                        <div>
                            <Row>
                                <Col className="mt-0 pb-0 mb-0">
                                    <h6 className="mb-1">Description:</h6>
                                </Col>
                            </Row>
                            <Row>
                            <Col className="modal-details mt-0 pb-0 mb-2">
                            {place.editorial_summary.overview.length > 250 ? !showDetails ?
                                <div>
                                    <p className="mb-1">{`${place.editorial_summary.overview.slice(0, 200)}...`}</p>
                                    <p className="cursor-pointer mt-0 font-weight-bold mb-0" variant="primary" onClick={toggleDetails}>More...</p>
                                </div>
                                :
                                <div>
                                    <p className="mb-1">{place.editorial_summary.overview}</p>
                                    <p className="cursor-pointer mb-0 mt-0 font-weight-bold" variant="primary" onClick={toggleDetails}>Hide</p>
                                </div>
                                :
                                <div>
                                    <p className="mb-1">{place.editorial_summary.overview}</p>
                                </div>

                            }
                            </Col>
                        </Row>
                        </div>
                    )}
                    {!!place.formatted_address && (
                        <Row>
                            <Col className="modal-details mb-1 mt-0">
                                <p className="m-0"><span className="font-weight-bold">Address:</span> {place.formatted_address}</p>
                            </Col>
                        </Row>
                    )}
                    {!!place.geometry && (
                        <Row>
                            <Col className='mb-1'>
                                <Button size="sm" className='rounded-pill btn-secondary' onClick={() => openGoogleMaps(place.geometry.location.lat, place.geometry.location.lng, place.place_id)}>Open Maps</Button>
                            </Col>
                        </Row>
                    )}
                    {!!place.international_phone_number && (
                        <Row>
                            <Col className="modal-details mb-1 mt-0">
                                <p className="m-0"><span className="font-weight-bold">Phone:</span> {place.international_phone_number}</p>
                            </Col>
                        </Row>
                    )}
                    {!!place.website && (
                        <Row>
                            <Col className="mb-2 mt-1">
                                <Button size="sm" onClick={e => openWebsiteTab(place.website, e)}  className="p-2 rounded-pill d-inline btn-secondary">
                                {/* <Image className="mt-2 mb-2 filter-white" style={{ height: "1.3em",  }} src={WebIcon} /> */}
                                Website
                                </Button>
                            </Col>
                            {/* <Col className="mb-1 mt-0">
                                <Image className="mr-1 d-inline filter-gray" style={{ height: "1.2em",  }} src={WebIcon} />
                                <p className='d-inline'><a className='modal-details' href={item.website}>{item.website}</a></p>
                            </Col> */}
                        </Row>
                    )}
                </Container>
            </Modal>
        </>
    );
};

export default InfoModal;